import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO />
    <div className="container">
      <div className="row">
        <div className="col-12">404</div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
